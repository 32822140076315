var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.imgBlock},[_c('v-carousel',{attrs:{"height":"232","hide-delimiter-background":"","show-arrows-on-hover":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.product.images),function(image,i){return _c('v-carousel-item',{key:i},[_c('v-sheet',{attrs:{"tile":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('img',{style:({
                filter: _vm.product.contracted
                  ? 'grayscale(100%)'
                  : 'grayscale(0)',
              }),attrs:{"src":image,"height":"232"}})])],1)],1)}),1),_c('span',[_c('product-label',{attrs:{"typeName":_vm.product.typeName,"isContracted":_vm.product.contracted}})],1)],1),_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style.name},[_vm._v(_vm._s(_vm.product.name))]),_c('div',{class:_vm.$style.item},[_c('b',[_vm._v("メーカー")]),_vm._v("："+_vm._s(_vm.product.maker))]),_c('div',{class:_vm.$style.item},[_c('b',[_vm._v("型式／型番")]),_vm._v("："+_vm._s(_vm.product.modelType))]),_c('div',{class:_vm.$style.amount},[_c('span',[_vm._v("希望販売金額")]),_c('b',[_vm._v(_vm._s(_vm.numberFormat(_vm.product.amount)))])])]),(_vm.product.contracted)?_c('div',{class:_vm.$style.contracted},[_c('span',[_vm._v("成約済み")])]):_c('div',{class:_vm.$style.buttonGroup},[(this.$store.state.favorites.indexOf(_vm.product.id) > -1)?[_c('v-btn',{attrs:{"color":"blue lighten-5"},on:{"click":function($event){return _vm.remove_favorite(_vm.product.id)}}},[_c('v-icon',{attrs:{"color":"blue","small":""}},[_vm._v(" mdi-check-bold ")]),_c('b',[_vm._v("追加済み")])],1)]:[_c('v-btn',{on:{"click":function($event){return _vm.add_favorite(_vm.product.id)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-plus ")]),_c('b',[_vm._v("リスト追加")])],1)],_c('v-dialog',{attrs:{"width":"1040px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" 製品詳細 ")])]}}])},[_c('v-card',[_c('product-detail',{attrs:{"product":_vm.product}})],1)],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }