import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export default new Vuex.Store({
  state: {
    authUser: {},
    isAuthenticated: false,
    jwt: localStorage.getItem('token'),
    endpoints: {
      // TODO: Remove hardcoding of dev endpoints
      obtainJWT: '/api/auth/jwt/create/',
      refreshJWT: '/api/auth/jwt/refresh/',
      baseUrl: '/'
    },
    favorites: [],
    favorites_str: localStorage.getItem('favorites_str'),
    msg: null,
    userInfo: {
      user_name: "",
      company_name: "",
      company_address: "",
      mail_address: "",
      tel_number: "",
      post_code: "",
    }
  },
  mutations: {
    setAuthUser(state, {
      authUser,
      isAuthenticated
    }) {
      Vue.set(state, 'authUser', authUser)
      Vue.set(state, 'isAuthenticated', isAuthenticated)
    },
    updateToken(state, newToken) {
      // TODO: For security purposes, take localStorage out of the project.
      localStorage.setItem('token', newToken);
      state.jwt = newToken;
    },
    removeToken(state) {
      // TODO: For security purposes, take localStorage out of the project.
      localStorage.removeItem('token');
      state.jwt = null;
    },
    setMessage(state, msg){
      Vue.set(state, 'msg', msg)
    },
    addFavorites(state, product_id) {
      // b = localStorage.getItem('favorites'),
      // b.split(",").map(x => Number(x))
      let nlist: number[] = state.favorites
      nlist.push(product_id)
      const array2 = Array.from(new Set(nlist))
      Vue.set(state, "favorites", array2)
      Vue.set(state, 'favorites_str', String(array2))
      localStorage.setItem('favorites_str', String(array2))
    },
    removeFavorites(state, product_id) {
      const newArray = state.favorites.filter(n => n !== product_id);
      const array2 = Array.from(new Set(newArray))
      Vue.set(state, "favorites", array2)
      Vue.set(state, 'favorites_str', String(array2))
      localStorage.setItem('favorites_str', String(array2))
    },
    clearFavorites(state) {
      Vue.set(state, "favorites", [])
    },
    setUserInfo(state, userInfo) {
      Vue.set(state, 'userInfo', userInfo)
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState(
      // {storage: window.sessionStorage}
    )
  ]
})
