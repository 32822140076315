









import Vue from "vue";

export default Vue.extend({
  name: "ProductLabel",
  props: {
    typeName: String,
    small: Boolean,
    isContracted: Boolean
  },
});
