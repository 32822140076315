














































































import Vue, { PropType } from 'vue';
import ProductDetail from './ProductDetail.vue';
import ProductLabel from './ProductLabel.vue';
import { ProductModel } from '../models/productModel';

export default Vue.extend({
  name: 'ProductCard',
  components: {
    ProductDetail,
    ProductLabel,
  },
  data: () => ({
    model: 0,
  }),
  props: {
    product: Object as PropType<ProductModel>,
  },
  methods: {
    add_favorite: function(id: number) {
      this.$store.commit('addFavorites', id);
    },
    remove_favorite: function(id: number) {
      this.$store.commit('removeFavorites', id);
    },
    numberFormat: function(value: number | undefined) {
      if (value) {
        return `¥${value.toLocaleString()}`;
      }
      return '未設定';
    },
  },
});
