



















































































































import { Component, Vue } from "vue-property-decorator";
import { ProductModel, ProductSize } from "../models/productModel";
import TwoColumnLayout from "../components/TwoColumnLayout.vue";
import ProductLabel from "../components/ProductLabel.vue";
import ProductColmun from "../components/ProductColmun.vue";
import SideBlock from "@/components/confirm/SideBlock.vue";
import { ConfirmType } from "@/models/contactModel";
@Component({
  components: {
    TwoColumnLayout,
    ProductLabel,
    ProductColmun,
    SideBlock,
  },
})
export default class Contact extends Vue {
  // productItems: ProductModel[] = productItems;
  confirmType = ConfirmType;
  productItems: ProductModel[] = [];
  user_name: string = "";
  company_name: string = "";
  company_address: string = "";
  mail_address: string = "";
  tel_number: string = "";
  post_code: string = "";
  case_detail: string = "";
  case_type: string = "";
  types: string[] = [];
  types_dict: { [key: string]: number } = {};
  // validation
  r_tel_number = /^[0-9]+$/;
  r_post_code= /^[0-9]{7}$/;
  r_mail_address = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  rules = {
    required: (value: any) => !!value || "入力してください",
    tel_number: (value: any) =>
      this.r_tel_number.test(value) ||
      "半角数字・ハイフン無しで入力してください",
    post_code: (value: any) => 
      this.r_post_code.test(value) || 
      '半角数字・ハイフン無し7桁で入力してください',
    mail_address: (value: any) =>
      this.r_mail_address.test(value) ||
      "有効なメールアドレスを入力してください",
  };
  // refs
  refs(): any {
    return this.$refs;
  }

  mounted() {
    // sessionから情報取得
    this.user_name = this.$store.state.userInfo.user_name;
    this.company_name = this.$store.state.userInfo.company_name;
    this.company_address = this.$store.state.userInfo.company_address;
    this.mail_address = this.$store.state.userInfo.mail_address;
    this.tel_number = this.$store.state.userInfo.tel_number;
    this.post_code = this.$store.state.userInfo.post_code;
    // お気に入り取得
    this.getFavorites();
    // 問い合わせ種別取得
    this.getCaseType();
  }

  getFavorites() {
    // お気に入り取得
    const params = new URLSearchParams();
    for (const f of this.$store.state.favorites) {
      params.append("id", f);
    }
    this.productItems = [];
    // axios
    this.axios({
      method: "get",
      url: "/api/item/",
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        for (var r in response.data.results) {
          var item = response.data.results[r];
          const productSize: ProductSize = {
            height: item.height,
            width: item.width,
            depth: item.depth,
            weight: item.weight,
          };
          const images = () => {
            if (item.is_resized) {
              const productImages: URL[] | undefined = [
                item.image01_resized,
                item.image02_resized,
                item.image03_resized,
                item.image04_resized,
                item.image05_resized,
              ];
              return productImages.filter((image) => image !== null);
            } else {
              const productImages: URL[] | undefined = [
                item.image01,
                item.image02,
                item.image03,
                item.image04,
                item.image05,
              ];
              return productImages.filter((image) => image !== null);
            } 
          };
          this.productItems.push({
            maker: item.maker,
            name: item.name,
            modelYear: item.model_year,
            modelType: item.model_number,
            typeName: item.item_type.name,
            area: item.area_prefecture,
            size: productSize,
            detail: item.model_detail,
            amount: item.price,
            images: images(),
            id: item.id,
          });
        }
      })
      .catch((error) => {
        window.alert(error);
        this.$store.commit("setMessage", error.response.data.detail);
      });
  }

  getCaseType() {
    // 問い合わせ種別を取得
    this.types_dict = {};
    this.axios({
      method: "get",
      url: "/api/casetype/",
      params: {
        with_items: true
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        for (const r of response.data.results) {
          this.types_dict[r.name] = r.id;
        }
        this.types = Object.keys(this.types_dict);
      })
      .catch((error) => {
        window.alert(error);
        this.$store.commit("setMessage", error.response.data.detail);
      });
  }

  createCase() {
    // validation
    // if (!this.$refs.form.validate()){
    if (!this.refs().form.validate()) {
      return;
    }
    // 問い合わせPOST
    const payload = {
      item_list_id: this.$store.state.favorites,
      case_type_id: this.types_dict[this.case_type],
      user_name: this.user_name,
      mail_address: this.mail_address,
      company_name: this.company_name,
      company_address: this.company_address,
      tel_number: this.tel_number,
      post_code: this.post_code,
      memo: this.case_detail,
    };
    this.axios({
      method: "post",
      url: "/api/case/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        window.alert(`登録しました ${response.data.id}`);
        this.$store.commit("clearFavorites");
        this.setUserInfo();
        this.$router.push("/finished")
      })
      .catch((error) => {
        window.alert(error);
        this.$store.commit("setMessage", error.response.data.detail);
        // this.$store.commit("clearFavorites")
      });
  }

  setUserInfo() {
    this.$store.commit("setUserInfo", {
      user_name: this.user_name,
      company_name: this.company_name,
      company_address: this.company_address,
      mail_address: this.mail_address,
      tel_number: this.tel_number,
      post_code: this.post_code,
    });
  }

  try_submit() {
    if (!this.refs().form.validate()){
      window.alert('入力不備がございます')
      return "NG"
    } else {
      this.createCase()
      return "OK"
    }
  }
}
