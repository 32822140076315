export type ProductModel = {
  maker: string;
  name?: string;
  modelYear?: string;
  modelType?: string;
  typeName?: string;
  area?: string;
  size?: ProductSize;
  detail?: string;
  amount?: number;
  images?: URL[];
  status?: string;
  contracted?: boolean;
  id: number;
};

export type ProductSize = {
  height?: string;
  width?: string;
  depth?: string;
  weight?: string;
}

export type ConfirmItems = [
  {
    title: string;
    valueItems: ValueItem[];
  }
];

export type ValueItem = {
  label: string;
  value: string;
}

export const formatter = new Intl.NumberFormat("ja-JP");

export const productItems: ProductModel[] = [
  {
    maker: "富士機械",
    name: "4頭NCフライス溝加工機　MAKINO SEIKI 牧野フライス精機　MHZ-4H",
    modelYear: "1985",
    modelType: "NUCLET-10",
    typeName: "NC旋盤",
    area: "茨城",
    amount: 9999999999,
    id: 900,
  },
  {
    maker: "北伸精器",
    name: "コンプレッサー　北伸精器　KS-707",
    modelYear: "1985",
    modelType: "NUCLET-10",
    typeName: "コンプレッサー",
    area: "茨城",
    amount: 9999999999,
    id: 901,
  },
  {
    maker: "TOYODA 豊田工機",
    name: "NC円筒研削盤　TOYODA 豊田工機　GE4P-50",
    modelYear: "1985",
    modelType: "NUCLET-10",
    typeName: "NC旋盤",
    area: "茨城",
    amount: 9999999999,
    id: 902,
  },
  {
    maker: "富士機械",
    name: "4頭NCフライス溝加工機　MAKINO SEIKI 牧野フライス精機　MHZ-4H",
    modelYear: "1985",
    modelType: "NUCLET-10",
    typeName: "NC旋盤",
    area: "茨城",
    amount: 9999999999,
    id: 903,
  },
  {
    maker: "TOYODA 豊田工機",
    name: "NC円筒研削盤　TOYODA 豊田工機　GE4P-50",
    modelYear: "1985",
    modelType: "NUCLET-10",
    typeName: "NC旋盤",
    area: "茨城",
    amount: 9999999999,
    id: 904,
  },
  {
    maker: "富士機械",
    name: "4頭NCフライス溝加工機　MAKINO SEIKI 牧野フライス精機　MHZ-4H",
    modelYear: "1985",
    modelType: "NUCLET-10",
    typeName: "NC旋盤",
    area: "茨城",
    amount: 9999999999,
    id: 905,
  },
];

export const MACHINE_TYPE = {
  New: '新着中古機械',
  Nc: 'NC工作機械'
} as const;

type MACHINE_TYPE = typeof MACHINE_TYPE[keyof typeof MACHINE_TYPE];