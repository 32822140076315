


























































import Vue, { PropType } from "vue";
import { ProductModel } from "@/models/productModel";
import ProductColmun from "@/components/ProductColmun.vue";

export default Vue.extend({
  name: "ContactItem",
  components: {
    ProductColmun,
  },
  props: {
    products: {
      type: Array as PropType<ProductModel[]>,
      required: false,
    },
    user_name: String,
    company_name: String,
    company_address: String,
    mail_address: String,
    tel_number: String,
    post_code: String,
    case_type: String,
    types_dict: {},
    case_detail: String,
  },
  methods: {
    check_data (val: any) {
      if (val == null || val == ""){
        return "入力なし"
      } else {
        return val
      }
    }
  }
});
