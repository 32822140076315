























































































































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ContactItem",
  // props: {
  //   product: Object as PropType<ProductModel>,
  // },
  data() {
    return {
      image01_url: null,
    }
  },
  props: {
    user_name: String,
    company_name: String,
    company_address: String,
    mail_address: String,
    tel_number: String,
    post_code: String,
    image01: File,
    image02: File,
    image03: File,
    image04: File,
    image05: File,
    name: String,
    height: Number,
    width: Number,
    depth: Number,
    weight: Number,
    item_type: String,
    area: String,
    maker: String,
    model_number: String,
    model_year: String,
    model_detail: String,
    price: Number,
    price_minimum: String,
    types: Array as PropType<String[]>,
    types_dict: {},
    areas: Array as PropType<String[]>,
  },
  methods: {
    get_url(image: File|null){
      if (image == null){
        return 
      } else {
        return window.URL.createObjectURL(image) 
      }
    },
    check_data (val: any) {
      if (val == null || val == ""){
        return "入力なし"
      } else {
        return val
      }
    },
    yenFormat: function(value: number | undefined) {
      if (value) {
        return `¥${Number(value).toLocaleString()}`;
      }
      return '入力なし';
    },
    numberFormat: function(value: number | undefined) {
      if (value) {
        return `${Number(value).toLocaleString()}`;
      }
      return '入力なし';
    },
  }
});
