













import Vue, { PropType } from "vue";
import PurchaseFlow from "./PurchaseFlow.vue"

export default Vue.extend({
  name: "FlowOpen",
  components: {
    PurchaseFlow
  },
  // data: () => ({
  //   model: 0,
  // }),
});
