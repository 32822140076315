








































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import TwoColumnLayout from '@/components/TwoColumnLayout.vue';
import SideBlock from '@/components/confirm/SideBlock.vue';
import { ConfirmType } from '@/models/contactModel';
// import vSelect from 'vue-select'

@Component({
  components: {
    TwoColumnLayout,
    SideBlock,
    // vSelect,
  },
})
export default class SellingFrom extends Vue {
  // types: string[] = Object.values(MACHINE_TYPE);
  // item_type_id: number;
  confirmType = ConfirmType;
  name: string = '';
  // height: number | null = null;
  // width: number | null = null;
  // depth: number | null = null;
  // weight: number | null = null;
  height: string = '';
  width: string = '';
  depth: string = '';
  weight: string = '';
  price_type: number = 0;
  item_type: string = '';
  area: string = '';
  image01: any = null;
  image02: any = null;
  image03: any = null;
  image04: any = null;
  image05: any = null;
  maker: string = '';
  model_number: string = '';
  model_year: string = '';
  model_detail: string = '';
  // price: number | null = null;
  // price_minimum: number | null = null;
  price: string = '';
  price_minimum: string = '';
  previewSrc: string | ArrayBuffer | null = null;
  types: string[] = [];
  types_dict: { [key: string]: number } = {};
  areas: string[] = [];
  makers: string[] = ['メーカー1', 'メーカー2'];
  // 問い合わせ情報
  user_name: string = '';
  company_name: string = '';
  company_address: string = '';
  mail_address: string = '';
  tel_number: string = '';
  post_code: string = '';
  // validation
  r_model_year = /^[0-9]{0,4}$/;
  r_number = /^[0-9]{0,}(\.){0,1}[0-9]{0,}$/;
  r_tel_number = /^[0-9]+$/;
  r_post_code = /^[0-9]{7}$/;
  r_mail_address = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  rules = {
    required: (value: any) => !!value || '入力してください',
    number: (value: any) =>
      this.r_number.test(value) || 
      '半角数字で入力してください。不明な場合は記入不要です',
    model_year: (value: any) =>
      this.r_model_year.test(value) || 
      '半角数字4桁で入力してください。不明な場合は記入不要です',
    tel_number: (value: any) =>
      this.r_tel_number.test(value) ||
      '半角数字・ハイフン無しで入力してください',
    post_code: (value: any) =>
      this.r_post_code.test(value) ||
      '半角数字・ハイフン無し7桁で入力してください',
    mail_address: (value: any) =>
      this.r_mail_address.test(value) ||
      '有効なメールアドレスを入力してください',
  };
  // refs
  refs(): any {
    return this.$refs;
  }

  mounted() {
    // sessionから情報取得
    this.user_name = this.$store.state.userInfo.user_name;
    this.company_name = this.$store.state.userInfo.company_name;
    this.company_address = this.$store.state.userInfo.company_address;
    this.mail_address = this.$store.state.userInfo.mail_address;
    this.tel_number = this.$store.state.userInfo.tel_number;
    this.post_code = this.$store.state.userInfo.post_code;
    //
    this.getItemType();
    this.getAreas();
  }

  createItem() {
    // validation
    if (!this.refs().form.validate()) {
      return;
    }
    // 問い合わせPOST
    let formData = new FormData();
    if (this.image01) {
      formData.append('image01', this.image01);
    }
    if (this.image02) {
      formData.append('image02', this.image02);
    }
    if (this.image03) {
      formData.append('image03', this.image03);
    }
    if (this.image04) {
      formData.append('image04', this.image04);
    }
    if (this.image05) {
      formData.append('image05', this.image05);
    }
    formData.append('name', this.name);
    if (this.height) {
      formData.append('height', String(this.height));
    }
    if (this.width) {
      formData.append('width', String(this.width));
    }
    if (this.depth) {
      formData.append('depth', String(this.depth));
    }
    if (this.weight) {
      formData.append('weight', String(this.weight));
    }
    formData.append('area_prefecture', this.area);
    formData.append('maker', this.maker);
    formData.append('model_number', this.model_number);
    formData.append('model_year', this.model_year);
    formData.append('model_detail', this.model_detail);
    formData.append('price', String(this.price));
    if (this.price_minimum) {
      formData.append('price_minimum', String(this.price_minimum));
    }
    formData.append('is_active', 'true');
    formData.append('item_type_id', String(this.types_dict[this.item_type]));
    // tmp
    formData.append('tmp_user_name', this.user_name);
    formData.append('tmp_company_name', this.company_name);
    formData.append('tmp_company_address', this.company_address);
    formData.append('tmp_mail_address', this.mail_address);
    formData.append('tel_number', this.tel_number);
    formData.append('post_code', this.post_code);
    // axios
    this.axios({
      method: 'post',
      url: '/api/item/',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
      .then((response) => {
        window.alert(`登録しました ${response.data.id}`);
        this.setUserInfo();
        this.$router.push('/finishedSelling');
      })
      .catch((error) => {
        if (error.response.data.hasOwnProperty('price')){
          window.alert("価格："+error.response.data.price)
        } else {
          window.alert(error)
        }
        this.$store.commit('setMessage', error.response.data.detail);
      });
  }

  selectedFile(e: File, no: number) {
    // 選択された File の情報を保存しておく
    // const reader = new FileReader();
    // reader.onload = e => {
    //   if (e !== null ){
    //     this.previewSrc = e.target.result;
    //   }
    // };
    // reader.readAsDataURL(e);
    if (no == 1) {
      this.image01 = e;
    } else if (no == 2) {
      this.image02 = e;
    } else if (no == 3) {
      this.image03 = e;
    } else if (no == 4) {
      this.image04 = e;
    } else if (no == 5) {
      this.image05 = e;
    }
  }

  getItemType() {
    // 商品種別を取得
    this.types_dict = {};
    this.axios({
      method: 'get',
      url: '/api/itemtype/',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        for (const r of response.data.results) {
          this.types_dict[r.name] = r.id;
          // this.types_dict.push({
          //   name: r.name,
          //   id: r.id,
          // })
        }
        // window.alert(this.types_dict[0].name)
        this.types = Object.keys(this.types_dict);
      })
      .catch((error) => {
        window.alert(error);
        this.$store.commit('setMessage', error.response.data.detail);
      });
    return true;
  }

  getAreas() {
    this.areas = [
      '北海道',
      '青森県',
      '岩手県',
      '宮城県',
      '秋田県',
      '山形県',
      '福島県',
      '茨城県',
      '栃木県',
      '群馬県',
      '埼玉県',
      '千葉県',
      '東京都',
      '神奈川県',
      '新潟県',
      '富山県',
      '石川県',
      '福井県',
      '山梨県',
      '長野県',
      '岐阜県',
      '静岡県',
      '愛知県',
      '三重県',
      '滋賀県',
      '京都府',
      '大阪府',
      '兵庫県',
      '奈良県',
      '和歌山県',
      '鳥取県',
      '島根県',
      '岡山県',
      '広島県',
      '山口県',
      '徳島県',
      '香川県',
      '愛媛県',
      '高知県',
      '福岡県',
      '佐賀県',
      '長崎県',
      '熊本県',
      '大分県',
      '宮崎県',
      '鹿児島県',
      '沖縄県',
    ];
    return true;
  }

  setUserInfo() {
    this.$store.commit('setUserInfo', {
      user_name: this.user_name,
      company_name: this.company_name,
      company_address: this.company_address,
      mail_address: this.mail_address,
      tel_number: this.tel_number,
      post_code: this.post_code,
    });
  }

  try_submit() {
    if (!this.refs().form.validate()) {
      window.alert('入力不備がございます');
      return 'NG';
    } else {
      this.createItem();
      return 'OK';
    }
  }
}
