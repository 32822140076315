

































































































































import { Vue } from 'vue-property-decorator';
import ProductCard from '@/components/ProductCard.vue';
import HeaderItem from '@/components/HeaderItem.vue';
// import KeyVisual from "@/components/KeyVisual.vue";
import { productItems, ProductSize } from '../models/productModel';

export default Vue.extend({
  name: 'Home',
  components: {
    ProductCard,
    HeaderItem,
  },
  data: () => ({
    productItems: productItems,
    navItems: [
      'NC工作機械',
      'コンプレッサー',
      'プレス',
      '一般工作機械',
      '板金機械',
      '鉄骨加工機械',
      '測定器・試験機',
    ],
  }),
  mounted: function() {
    this.productItems = [];
    this.axios({
      method: 'get',
      url: '/api/item/',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
        for (var r in response.data.results.slice(0,6)) {
          var item = response.data.results[r];
          let productSize: ProductSize = {
            height: item.height,
            width: item.width,
            depth: item.depth,
            weight: item.weight,
          };
          const images = () => {
            if (item.is_resized) {
              const productImages: URL[] | undefined = [
                item.image01_resized,
                item.image02_resized,
                item.image03_resized,
                item.image04_resized,
                item.image05_resized,
              ];
              return productImages.filter((image) => image !== null);
            } else {
              const productImages: URL[] | undefined = [
                item.image01,
                item.image02,
                item.image03,
                item.image04,
                item.image05,
              ];
              return productImages.filter((image) => image !== null);
            } 
          };
          const isContracted = () => item.status === '成約済み';
          this.productItems.push({
            maker: item.maker,
            name: item.name,
            modelYear: item.model_year,
            modelType: item.model_number,
            typeName: item.item_type.name,
            area: item.area_prefecture,
            size: productSize,
            detail: item.model_detail,
            amount: item.price,
            images: images(),
            status: item.status,
            contracted: isContracted(),
            id: item.id,
          });
        }
      })
      .catch((e) => {
        window.alert(e);
      });
  },
});
