


























































































import { Component, Vue } from "vue-property-decorator";
import TwoColumnLayout from "@/components/TwoColumnLayout.vue";
import { ConfirmType } from "@/models/contactModel";
import SideBlock from "@/components/confirm/SideBlock.vue";

// export default Vue.extend({
//   name: "Contact",
//   components: {
//     TwoColumnLayout,
//     SideBlock,
//   },
//   data() {
//     return {
//       items: ["購入に関するお問い合わせ", "売却に関するお問い合わせ", "その他"],
//     };
//   },
// });
@Component({
  components: {
    TwoColumnLayout,
    SideBlock,
  },
})

export default class Contact extends Vue {
  confirmType = ConfirmType;
  // 問い合わせ情報
  user_name: string = "";
  company_name: string = "";
  company_address: string = "";
  mail_address: string = "";
  tel_number: string = "";
  post_code: string = "";
  case_detail: string = "";
  case_type: string = "";
  types: string[] = ["購入に関する問い合わせ", "売却に関する問い合わせ", "その他"];
  types_dict: { [key: string]: number; } = {
    "購入に関する問い合わせ": 3,
    "売却に関する問い合わせ": 4,
    "その他": 5,
  };
  // validation
  r_tel_number = /^[0-9]+$/;
  r_post_code = /^[0-9]{7}$/;
  r_mail_address = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  rules= {
    required: (value: any) => !!value || '入力してください',
    tel_number: (value: any) => this.r_tel_number.test(value) || '半角数字・ハイフン無しで入力してください',
    post_code: (value: any) => this.r_post_code.test(value) || '半角数字・ハイフン無し7桁で入力してください',
    mail_address: (value: any) => this.r_mail_address.test(value) || '有効なメールアドレスを入力してください',
  }
  // refs
  refs():any {
    return this.$refs;
  }

  mounted(){
    // sessionから情報取得
    this.user_name = this.$store.state.userInfo.user_name
    this.company_name = this.$store.state.userInfo.company_name
    this.company_address = this.$store.state.userInfo.company_address
    this.mail_address = this.$store.state.userInfo.mail_address
    this.tel_number = this.$store.state.userInfo.tel_number
    this.post_code = this.$store.state.userInfo.post_code
    //
    this.getCaseType()
  }

  setUserInfo(){
    this.$store.commit("setUserInfo", 
      {
        user_name: this.user_name,
        company_name: this.company_name,
        company_address: this.company_address,
        mail_address: this.mail_address,
        tel_number: this.tel_number,
        post_code: this.post_code
      }
    )
  }

  check_validation(){
    if (!this.refs().form.validate()){
      return true 
    } else {
      return false
    }
  }

  createCase(){
    // validation
    if (!this.refs().form.validate()){
      window.alert('入力不備がございます')
      return
    } 
    // 問い合わせPOST
    const payload = {
      case_type_id: this.types_dict[this.case_type],
      user_name: this.user_name,	
      mail_address: this.mail_address,	
      company_name: this.company_name,	
      company_address: this.company_address,
      post_code: this.post_code,
      tel_number: this.tel_number,
      memo: this.case_detail,
      item_list_id: [],
    }
    this.axios({
      method: "post",
      url: "/api/case/",
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => { 
      window.alert(`登録しました ${response.data.id}`)
      this.setUserInfo()
      this.$router.push("/finished")
    })
    .catch((error) => {
      window.alert(error)
      this.$store.commit("setMessage", error.response.data.detail)
      // this.$store.commit("clearFavorites")
    }) 
  }

  getCaseType(){
    // 問い合わせ種別を取得
    this.types_dict = {}
    this.axios({
      method: "get",
      url: "/api/casetype/",
      params: {
        with_items: false
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      for (const r of response.data.results){
        this.types_dict[r.name] = r.id
      }
      this.types = Object.keys(this.types_dict)
    })
    .catch((error) => {
      window.alert(error)
      this.$store.commit("setMessage", error.response.data.detail)
    })
  }

  try_submit() {
    if (!this.refs().form.validate()){
      window.alert('入力不備がございます')
      return "NG"
    } else {
      this.createCase()
      return "OK"
    }
  }
}

