































































































import Vue, { PropType } from 'vue';
import { ProductModel, formatter } from '../models/productModel';
import ProductLabel from '../components/ProductLabel.vue';
import FlowOpen from '../components/flow/FlowOpen.vue';

export default Vue.extend({
  name: 'ProductDetail',
  components: {
    ProductLabel,
    FlowOpen,
  },
  data: () => ({
    model: 0,
  }),
  props: {
    product: Object as PropType<ProductModel>,
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    add_favorite: function(id: number) {
      this.$store.commit('addFavorites', id);
    },
    remove_favorite: function(id: number) {
      this.$store.commit('removeFavorites', id);
    },
    numberFormat: function(value: number | undefined) {
      if (value) {
        return `¥${value.toLocaleString()}`;
      }
      return '未設定';
    },
  },
});
