























import Vue from "vue";
import HeaderItem from "@/components/HeaderItem.vue";

export default Vue.extend({
  name: "Finished",
  components: {
    HeaderItem,
  },
});
