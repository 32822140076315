









import Vue from 'vue';
import TwoColumnLayout from '../components/TwoColumnLayout.vue';
export default Vue.extend({
  name: 'NotFound',
  components: {
    TwoColumnLayout,
  },
});
