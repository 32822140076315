

































































































































































import { Component, Vue } from 'vue-property-decorator';
import ProductCard from '../components/ProductCard.vue';
import TwoColumnLayout from '../components/TwoColumnLayout.vue';
import FlowOpen from '../components/flow/FlowOpen.vue';
import { ProductModel, ProductSize } from '../models/productModel';

@Component({
  components: {
    ProductCard,
    TwoColumnLayout,
    FlowOpen,
  },
})
export default class Buy extends Vue {
  navItems: string[] = [
    '全て',
    'NC工作機械',
    'コンプレッサー',
    'プレス',
    '一般工作機械',
    '板金機械',
    '鉄骨加工機械',
    '測定器・試験機',
    'その他',
  ];
  productItems: ProductModel[] = [];
  currentItem: string | null = null;
  search: string | null = null;
  sortItems: string[] = ['新着順', '安い順', '高い順'];
  sort_selected: string = this.sortItems[0];
  order_by: string = '-created_at';
  order_by_dict: { [key: string]: string } = {
    新着順: '-created_at',
    安い順: 'price',
    高い順: '-price',
  };
  prefectures_selected: string[] = [];
  maker: string | null = null;
  price_max: number | null = null;
  price_min: number | null = null;

  isPriceDialog: boolean = false;
  isPrefDialog: boolean = false;

  set_price() {
    this.getItems();
    this.isPriceDialog = false;
  }

  clear_price() {
    this.price_max = null;
    this.price_min = null;
    this.getItems();
    this.isPriceDialog = false;
  }

  set_prefectures_selected() {
    this.getItems();
    this.isPrefDialog = false;
  }

  clear_prefectures_selected() {
    this.prefectures_selected = [];
    this.getItems();
    this.isPrefDialog = false;
  }

  setItem(itemName: string) {
    if (this.currentItem == itemName) {
      return;
    } else {
      this.currentItem = itemName;
      this.getItems();
    }
  }

  set_order_by() {
    this.order_by = this.order_by_dict[this.sort_selected];
    this.getItems();
  }

  mounted() {
    // Backendとの連携
    let val: string = '';
    if (typeof this.$route.query.category == 'string') {
      val = this.$route.query.category;
      if (this.navItems.includes(val)) {
        // 有効なパラメータ有りの場合
        this.setItem(val);
      } else {
        // その他
        this.setItem('全て');
      }
    } else {
      // その他
      this.setItem('全て');
    }
  }

  getItems() {
    this.productItems = [];
    // paramas
    const params = new URLSearchParams();
    if (this.currentItem != "全て" && this.currentItem != null) {
       params.append("category_large", this.currentItem)
    }
    if (this.order_by) {
      params.append('order_by', this.order_by);
    }
    if (this.maker) {
      params.append('maker_icontains', this.maker);
    }
    if (this.price_max) {
      params.append('price_max', String(this.price_max));
    }
    if (this.price_min) {
      params.append('price_min', String(this.price_min));
    }
    for (let p of this.prefectures_selected) {
      params.append('area_prefecture', p);
    }
    // ajax
    this.axios({
      method: 'get',
      url: '/api/item/',
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        for (var r in response.data.results) {
          var item = response.data.results[r];
          let productSize: ProductSize = {
            height: item.height,
            width: item.width,
            depth: item.depth,
            weight: item.weight,
          };
          const images = () => {
            if (item.is_resized) {
              const productImages: URL[] | undefined = [
                item.image01_resized,
                item.image02_resized,
                item.image03_resized,
                item.image04_resized,
                item.image05_resized,
              ];
              return productImages.filter((image) => image !== null);
            } else {
              const productImages: URL[] | undefined = [
                item.image01,
                item.image02,
                item.image03,
                item.image04,
                item.image05,
              ];
              return productImages.filter((image) => image !== null);
            } 
          };
          const isContracted = () => item.status === '成約済み';
          this.productItems.push({
            maker: item.maker,
            name: item.name,
            modelYear: item.model_year,
            modelType: item.model_number,
            typeName: item.item_type.name,
            area: item.area_prefecture,
            size: productSize,
            detail: item.model_detail,
            amount: item.price,
            images: images(),
            status: item.status,
            contracted: isContracted(),
            id: item.id,
          });
        }
      })
      .catch((e) => {
        window.alert(e);
      });
  }

  prefectures: any[] = [
    { code: 1, name: '北海道' },
    { code: 2, name: '青森県' },
    { code: 3, name: '岩手県' },
    { code: 4, name: '宮城県' },
    { code: 5, name: '秋田県' },
    { code: 6, name: '山形県' },
    { code: 7, name: '福島県' },
    { code: 8, name: '茨城県' },
    { code: 9, name: '栃木県' },
    { code: 10, name: '群馬県' },
    { code: 11, name: '埼玉県' },
    { code: 12, name: '千葉県' },
    { code: 13, name: '東京都' },
    { code: 14, name: '神奈川県' },
    { code: 15, name: '新潟県' },
    { code: 16, name: '富山県' },
    { code: 17, name: '石川県' },
    { code: 18, name: '福井県' },
    { code: 19, name: '山梨県' },
    { code: 20, name: '長野県' },
    { code: 21, name: '岐阜県' },
    { code: 22, name: '静岡県' },
    { code: 23, name: '愛知県' },
    { code: 24, name: '三重県' },
    { code: 25, name: '滋賀県' },
    { code: 26, name: '京都府' },
    { code: 27, name: '大阪府' },
    { code: 28, name: '兵庫県' },
    { code: 29, name: '奈良県' },
    { code: 30, name: '和歌山県' },
    { code: 31, name: '鳥取県' },
    { code: 32, name: '島根県' },
    { code: 33, name: '岡山県' },
    { code: 34, name: '広島県' },
    { code: 35, name: '山口県' },
    { code: 36, name: '徳島県' },
    { code: 37, name: '香川県' },
    { code: 38, name: '愛媛県' },
    { code: 39, name: '高知県' },
    { code: 40, name: '福岡県' },
    { code: 41, name: '佐賀県' },
    { code: 42, name: '長崎県' },
    { code: 43, name: '熊本県' },
    { code: 44, name: '大分県' },
    { code: 45, name: '宮崎県' },
    { code: 46, name: '鹿児島県' },
    { code: 47, name: '沖縄県' },
  ];
}
