














































import Vue from 'vue';
import FooterBlock from './components/layout/FooterBlock.vue';
import VueGtm from 'vue-gtm';

export default Vue.extend({
  name: 'App',
  components: {
    FooterBlock,
  },
  data() {
    return {
      drawer: false,
    };
  },
});

Vue.use(VueGtm, {
  id: 'GTM-PXT5JM5',
});
