
































































import Vue from 'vue';
import ProductCard from '../../components/ProductCard.vue';
import TwoColumnLayout from '../../components/TwoColumnLayout.vue';
import SellingLp from './SellingLp.vue';

export default Vue.extend({
  name: 'Sell',
  components: {
    ProductCard,
    TwoColumnLayout,
    SellingLp,
  },
});
