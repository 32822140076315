




































import Vue, { PropType } from 'vue';
import ProductLabel from './ProductLabel.vue';
import ProductDetail from './ProductDetail.vue';
import { ProductModel } from '../models/productModel';

export default Vue.extend({
  name: 'ProductColmun',
  components: {
    ProductLabel,
    ProductDetail,
  },
  props: {
    products: Array as PropType<ProductModel[]>,
  },
  methods: {
    remove_favorite: function(id: number) {
      if (window.confirm('検討リストから削除してよろしいですか？')) {
        this.$store.commit('removeFavorites', id);
      }
    },
    is_in_cart: function(id: number) {
      return this.$store.state.favorites.indexOf(id) > -1;
    },
  },
});
