import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Buy from "../views/Buy.vue";
import Sell from "../views/sell/Sell.vue";
import Cart from "../views/Cart.vue";
import PurchaseFrom from "../views/PurchaseFrom.vue";
import SellingFrom from "../views/sell/SellingFrom.vue";
import Privacy from "../views/Privacy.vue";
import Terms from "../views/Terms.vue";
import Tokushou from "../views/Tokushou.vue";
import Company from "../views/Company.vue";
import Contact from "../views/Contact.vue";
import Finished from "../views/Finished.vue";
import FinishedSelling from "../views/FinishedSelling.vue";
import NotFound from '../views/NotFound.vue'; //追記

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/', 
    redirect: '/home' 
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/buy",
    name: "Buy",
    component: Buy,
  },
  {
    path: "/sell",
    name: "Sell",
    component: Sell,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/cart/purchase-from",
    name: "PurchaseFrom",
    component: PurchaseFrom,
  },
  {
    path: "/sell/selling-form",
    name: "SellingFrom",
    component: SellingFrom,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/tokushou",
    name: "Tokushou",
    component: Tokushou,
  },
  {
    path: "/company",
    name: "Company",
    component: Company,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/finished",
    name: "Finished",
    component: Finished,
  },
  {
    path: "/finishedSelling",
    name: "FinishedSelling",
    component: FinishedSelling,
  },
  //404向け
  {
    path: '*',　
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
});

export default router;
