


























import { Component, Vue } from 'vue-property-decorator';
import { ProductModel, ProductSize } from '../models/productModel';
import TwoColumnLayout from '../components/TwoColumnLayout.vue';
import ProductColmun from '../components/ProductColmun.vue';
import FlowOpen from '../components/flow/FlowOpen.vue';
@Component({
  components: {
    TwoColumnLayout,
    ProductColmun,
    FlowOpen,
  },
})
export default class Cart extends Vue {
  // productItems: ProductModel[] = productItems;
  productItems: ProductModel[] = [];

  mounted() {
    if (this.$store.state.favorites.length == 0) {
      window.alert('カートは空です');
    } else {
      const params = new URLSearchParams();
      for (const f of this.$store.state.favorites) {
        params.append('id', f);
      }
      this.productItems = [];
      this.axios({
        method: 'get',
        url: '/api/item/',
        params: params,
        headers: {
          // 'Authorization': `JWT ${this.$store.state.jwt}`,  // POSTや問い合わせGET時は認証必須
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let data_list = response.data.results
        if (response.data.count != this.$store.state.favorites.length){
          window.alert("取り下げられた中古機械を除いて表示します")
          this.$store.commit("clearFavorites");
          for (var r in response.data.results){
            let id = response.data.results[r].id
            this.$store.commit("addFavorites", id);
          }
        }
        let seiyaku_flag = false;
        for (var r in data_list) {
          var item = data_list[r];
            if (item.status == "成約済み"){
              this.$store.commit("removeFavorites", item.id);
              seiyaku_flag = true
              continue
            }
          const productSize: ProductSize = {
            height: item.height,
            width: item.width,
            depth: item.depth,
            weight: item.weight,
          };
          const images = () => {
            if (item.is_resized) {
              const productImages: URL[] | undefined = [
                item.image01_resized,
                item.image02_resized,
                item.image03_resized,
                item.image04_resized,
                item.image05_resized,
              ];
              return productImages.filter((image) => image !== null);
            } else {
              const productImages: URL[] | undefined = [
                item.image01,
                item.image02,
                item.image03,
                item.image04,
                item.image05,
              ];
              return productImages.filter((image) => image !== null);
            } 
          };
          this.productItems.push({
            maker: item.maker,
            name: item.name,
            modelYear: item.model_year,
            modelType: item.model_number,
            typeName: item.item_type.name,
            area: item.area_prefecture,
            size: productSize,
            detail: item.model_detail,
            amount: item.price,
            images: images(),
            id: item.id,
          });
        }
        if (seiyaku_flag) {
          window.alert("成約済みの中古機械を除外して表示します")
        }
      })
      .catch((error) => {
        window.alert(error);
        this.$store.commit("setMessage", error.response.data.detail);
      });
    }
  }
}
