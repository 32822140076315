






















































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ContactItem",
  props: {
    user_name: String,
    company_name: String,
    company_address: String,
    mail_address: String,
    tel_number: String,
    post_code: String,
    case_type: String,
    case_detail: String,
  },
  methods: {
    check_data (val: any) {
      if (val == null || val == ""){
        return "入力なし"
      } else {
        return val
      }
    }
  }
});
