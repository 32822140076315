import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=2d3546ca&"
import script from "./ProductCard.vue?vue&type=script&lang=ts&"
export * from "./ProductCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProductCard.vue?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./ProductCard.vue?vue&type=style&index=1&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCard,VCarousel,VCarouselItem,VDialog,VIcon,VRow,VSheet})
