































































































import Vue , { PropType } from "vue";
import ContactItem from "@/components/confirm/ContactItem.vue";
import SellingItem from "@/components/confirm/SellingItem.vue";
import PurchaseItem from "@/components/confirm/PurchaseItem.vue";
import { ConfirmType } from "@/models/contactModel";
import { ProductModel } from "@/models/productModel";
export default Vue.extend({
  name: "SideBlock",
  components: {
    ContactItem,
    SellingItem,
    PurchaseItem,
  },
  props: {
    description: String,
    viewType: String,
    products: {
      type: Array as PropType<ProductModel[]>,
      required: false
    },
    user_name: String,
    company_name: String,
    company_address: String,
    mail_address: String,
    tel_number: String,
    post_code: String,
    // Case
    case_type: String,
    case_detail: String,
    // Selling
    image01: File,
    image02: File,
    image03: File,
    image04: File,
    image05: File,
    name: String,
    height: Number,
    width: Number,
    depth: Number,
    weight: Number,
    item_type: String,
    area: String,
    maker: String,
    model_number: String,
    model_year: String,
    model_detail: String,
    price: Number,
    price_minimum: String,
    types: Array as PropType<String[]>,
    areas: Array as PropType<String[]>,
  },
  data() {
    return {
      dialog: false,
      confirmType: ConfirmType,
    };
  },
  methods: {
    try_submit(){
      this.$emit("try_submit")
      this.dialog = false
    }
  }
});
