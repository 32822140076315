







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TwoColumnLayout extends Vue {
  @Prop({ default: "" })
  pageTitle!: string;
}
